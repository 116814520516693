function add_validator_methods() {

    function check_ck_editor() {
        if (CKEDITOR.instances.listing_description.getData() == '') {
            return false;
        } else {
            $("#error_check_editor").empty();
            return true;
        }
    }

    // If some element is required, it should be validated even if it's hidden
    $.validator.setDefaults({ignore: []});

    //$.validator.setDefaults({ ignore: ":hidden:not(.chosen-select)" });

    $.validator.addMethod("accept",
        function (value, element, param) {
            return value.match(new RegExp(/(\.jpe?g|\.gif|\.png|^$)/i));
        }
    );

    $.validator.addMethod("regex",
        function (value, element, regexp) {
            var re = new RegExp(regexp);
            return re.test(value);
        }
    );

    $.validator.addClassRules("required", {
        required: true
    });

    $.validator.addMethod('validUrl', function (value, element) {
        var url = $.validator.methods.url.bind(this);
        return url(value, element) || url('http://' + value, element);
    }, 'Please enter a valid URL');

    $.validator.addClassRules("url", {
        validUrl: true
    });

    $.validator.addMethod("noSpace", function (value, element) {
        return value.indexOf(" ") < 0 && value != "";
    }, "No space please and don't leave it empty");

    $.validator.addMethod("trimSpace", function (value, element) {
        value = value.trim();
        return value != "";
    }, "don't leave it empty");

    $.extend(jQuery.validator.messages, {
        required: "Dit veld is verplicht",
        email: "Gelieve een geldig e-mailadres in te geven.",
        equalTo: 'Voer dezelfde waarde opnieuw in',
        minlength: $.validator.format("Voer minimaal {0} tekens in")
    });

}

function initialize_defaults() {
    add_validator_methods();
}

function initialize_form_validation(ele_id) {
    add_validator_methods();
    var form_id = "#" + ele_id;
    $(form_id).validate({
        ignore: ":hidden",
        rules: {
            "user[gender]": {required: true},
            "user[email]": {required: true, email: true},
            "user[first_name]": {required: true},
            "user[last_name]": {required: true},
            "user[house_number]": {required: true},
            "user[street_name]": {required: true},
            "user[city]": {required: true},
            "user[postal_code]": {required: true},
            "user[country]": {required: true},
            "user[telephone]": {required: true},
            "user[password]": {required: true, minlength: 4, maxlength: 128},
            "user[password_confirmation]": {required: true, equalTo: '#checkoutFrom [name="user[password]"]'},
            "agree_with_terms": {required: true}
        },
        errorPlacement: function (error, element) {
            if (element.hasClass('image-radio')) {
                element.closest(".imageuploadify-images-list").append(error);
            } else if (element.hasClass('form-check-input')) {
                element.closest('.form-group').append(error);
            } else {
                element.closest("div").append(error);
            }
        },
        messages: {},
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function (form) {
            disable_and_submit(form_id, form, "false");
        }
    });
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id);
    form.submit();
}

function disable_submit_button(form_id) {
    $(form_id).find(".postlistingfinishBtn").prop("disabled", true);
    // add spinner to button
    $(form_id).find(".postlistingfinishBtn").html(
        `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
    );
}

export default initialize_form_validation;
initialize_defaults;
